<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>
          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="infoStatus">
        <v-alert dark color="primary">
          Application for {{ applicationType }}. Submission deadline at
          {{ deadline }} , Status: {{ infoStatus }}
        </v-alert>
      </v-col>
      <v-col cols="12" v-if="infoStatus">
        <v-row justify="center" align="center" no-gutters>
          <v-card min-width="640">
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>Application Form</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  outlined
                  @click="handlePreview()"
                  v-if="infoStatus != 'empty'"
                >
                  Preview
                </v-btn>
                <template v-if="infoStatus == 'saved' || infoStatus == 'empty'">
                  <v-btn color="primary lighten-1" @click="handleSaveBasic()">
                    Save
                  </v-btn>
                </template>
                <template v-else-if="infoStatus == 'confirmed'">
                  <v-btn
                    color="primary lighten-1"
                    @click="handleSaveResearch()"
                  >
                    Save
                  </v-btn>
                </template>
              </v-card-actions>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="formValid"
              @submit.prevent="disableSubmit()"
              lazy-validation
            >
              <v-tabs vertical>
                <v-tab>
                  <v-icon left> mdi-account </v-icon>
                  Basic
                </v-tab>
                <template v-if="infoStatus != 'saved' && infoStatus != 'empty'">
                  <v-tab>
                    <v-icon left> mdi-school </v-icon>
                    Research
                  </v-tab>
                  <v-tab>
                    <v-icon left> mdi-email </v-icon>
                    Reference
                  </v-tab>
                </template>

                <v-card v-if="userFiles.photo">
                  <v-avatar color="grey" tile width="160" height="180">
                    <v-img :src="userApplicationInfo.photo_thumbnail"></v-img>
                  </v-avatar>
                  <v-btn
                    @click="handleFileDelete('photo')"
                    color="pink"
                    dark
                    small
                    absolute
                    bottom
                    right
                    fab
                    :disabled="phase != 'basic'"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
                <v-card v-else>
                  <v-avatar
                    color="grey lighten-1"
                    tile
                    width="160"
                    height="180"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            handleUpload('photo', 'ID Photo', fileTypes.image)
                          "
                          color="green"
                          dark
                          fab
                          :disabled="phase != 'basic'"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Upload Photo</span>
                    </v-tooltip>
                  </v-avatar>
                </v-card>

                <v-dialog
                  persistent
                  v-model="deleteFileDialog"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title class="headline">Remove</v-card-title>
                    <v-card-subtitle v-if="deleteFileField">
                      you are about to delete uploaded {{ deleteFileField }}
                    </v-card-subtitle>
                    <v-card-text v-if="userFiles[deleteFileField]">
                      <p>Name: {{ userFiles[deleteFileField].name }}</p>
                      <p>Size: {{ userFiles[deleteFileField].size }} bytes</p>
                      <p>Time: {{ userFiles[deleteFileField].time }}</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn color="grey" @click="deleteFileClose">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="deleteFileConfirm"
                        :disabled="phase != 'basic' && phase != 'confirmed'"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog persistent v-model="uploadDialog" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >Upload {{ uploadDesc }}</v-card-title
                    >

                    <v-card-text>
                      <v-file-input
                        ref="fileupload"
                        @change="selectFile"
                        chips
                        show-size
                        counter
                        :disabled="
                          isUploading ||
                          (phase != 'basic' && phase != 'confirmed')
                        "
                        :accept="uploadTypes"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-actions v-if="isUploading" class="justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-card-actions>
                    <v-card-actions v-else class="justify-center">
                      <v-btn color="grey" @click="uploadClose">Cancel</v-btn>
                      <v-btn
                        color="primary"
                        :disabled="!uploadFile"
                        @click="uploadSubmit"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialog" persistent max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="green"
                      dark
                      class="mt-10 mb-5"
                      v-if="infoStatus == 'saved' || infoStatus == 'returned'"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Next
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Confirm Basic Information
                    </v-card-title>
                    <v-card-text>
                      <v-alert
                        type="error"
                        v-if="confirmPrecheck"
                        v-text="confirmPrecheck"
                      >
                      </v-alert>
                      <v-alert type="warning" v-else v-text="confirmAlert">
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey darken-1"
                        text
                        @click="confirmDialog = false"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        color="warning darken-1"
                        text
                        @click="handleRefresh()"
                        v-if="confirmPrecheck"
                      >
                        Refresh
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="handleConfirm()"
                        v-if="!confirmPrecheck"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="submitDialog" persistent max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="green"
                      dark
                      class="mt-10 mb-5"
                      v-if="infoStatus == 'confirmed'"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Submit
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Submit Application
                    </v-card-title>
                    <v-card-text>
                      <v-alert
                        type="error"
                        v-if="submitPrecheck"
                        v-text="submitPrecheck"
                      >
                      </v-alert>
                      <v-alert type="warning" v-else>
                        You would not be able to change information once
                        submitted.
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey darken-1"
                        text
                        @click="submitDialog = false"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="handleSubmit()"
                        v-if="!submitPrecheck"
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-tab-item>
                  <v-card-text>
                    <v-text-field
                      v-model="userApplicationInfo.full_name"
                      :counter="50"
                      :rules="rules.required"
                      :label="infoFields.full_name"
                      required
                      hint="note: please ensure your name submitted is in accordance to your Identification Card or Passport"
                      persistent-hint
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-text-field
                      v-model="userApplicationInfo.name_cn"
                      :counter="20"
                      :label="infoFields.name_cn + ' [Optional]'"
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-text-field
                      v-model="userApplicationInfo.native_name"
                      :counter="50"
                      :label="infoFields.native_name + ' [Optional]'"
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-select
                      v-if="applicationType == 'faculty'"
                      v-model="userApplicationInfo.position_type"
                      :items="positionTypes"
                      :label="infoFields.position_type"
                      required
                      :disabled="phase != 'basic'"
                    ></v-select>
                    <v-text-field
                      v-model="userApplicationInfo.nationality"
                      :counter="50"
                      :rules="rules.required"
                      :label="infoFields.nationality"
                      required
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="userApplicationInfo.birthday"
                          :label="infoFields.birthday"
                          prepend-icon="mdi-calendar"
                          :rules="rules.required"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          :disabled="phase != 'basic'"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="userApplicationInfo.birthday">
                      </v-date-picker>
                    </v-menu>
                    <v-autocomplete
                      v-model="userApplicationInfo.timezone"
                      :label="infoFields.timezone"
                      :items="timezones"
                      :disabled="phase != 'basic'"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="userApplicationInfo.current_institute"
                      :counter="100"
                      :rules="rules.required"
                      :label="infoFields.current_institute"
                      required
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-text-field
                      v-model="userApplicationInfo.current_position"
                      :counter="1000"
                      :rules="rules.required"
                      :label="infoFields.current_position"
                      required
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-text-field
                      v-model="userApplicationInfo.current_address"
                      :counter="100"
                      :rules="rules.required"
                      :label="infoFields.current_address"
                      required
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-text-field
                      v-model="userApplicationInfo.phd_institute"
                      :counter="100"
                      :rules="rules.required"
                      :label="infoFields.phd_institute"
                      required
                      :disabled="phase != 'basic'"
                    ></v-text-field>
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="userApplicationInfo.phd_date"
                          :label="infoFields.phd_date"
                          prepend-icon="mdi-calendar"
                          :rules="rules.required"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          hint="format: YYYY-MM (certificate time or expected time of obtaining the certificate)"
                          persistent-hint
                          :disabled="phase != 'basic'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        type="month"
                        color="primary"
                        min="1970-01"
                        max="2100-12"
                        v-model="userApplicationInfo.phd_date"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-card-text>
                </v-tab-item>

                <template v-if="infoStatus != 'saved' && infoStatus != 'empty'">
                  <v-tab-item>
                    <v-card-text>
                      <v-data-table
                        dense
                        :headers="researchHeaders"
                        :items="researchInfo"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense>
                            <v-toolbar-title>Research Info</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                              persistent
                              v-model="researchDialog"
                              max-width="400px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="phase != 'confirmed'"
                                >
                                  Add
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title> Add Research Info </v-card-title>

                                <v-card-text>
                                  <v-select
                                    v-model="researchAreaSelect"
                                    :items="researchAreaOptions"
                                    label="Research Area"
                                    solo
                                    :disabled="phase != 'confirmed'"
                                  ></v-select>
                                  <v-text-field
                                    v-model="newResearch.area"
                                    :rules="rules.required"
                                    label="Research Area"
                                    v-if="isOtherResearchArea"
                                    :disabled="phase != 'confirmed'"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newResearch.interest"
                                    :rules="rules.required"
                                    label="Research Interest"
                                    :disabled="phase != 'confirmed'"
                                  ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="researchClose"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    :disabled="
                                      !newResearch.area ||
                                      !newResearch.interest ||
                                      phase != 'confirmed'
                                    "
                                    color="primary darken-1"
                                    text
                                    @click="researchSave"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="researchDialogDelete"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="headline"
                                  >Are you sure you want to delete this research
                                  info?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="deleteResearchClose"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="deleteResearchConfirm"
                                    :disabled="phase != 'confirmed'"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            @click="deleteResearchItem(item)"
                            :disabled="phase != 'confirmed'"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>

                      <v-text-field
                        v-model="userApplicationInfo.ads_link"
                        :rules="rules.required"
                        :label="infoFields.ads_link"
                        required
                        :disabled="phase != 'confirmed'"
                      ></v-text-field>

                      <v-alert
                        dark
                        color="primary lighten-1"
                        dense
                        max-width="500"
                      >
                        Please combine your CV, a list of publications, a
                        statement of previous and future research and other
                        materials in a single PDF file, and upload here
                      </v-alert>
                      <v-row justify="center" align="center">
                        <v-col cols="11">
                          <v-text-field
                            disabled
                            :value="
                              userFiles.material ? userFiles.material.name : ''
                            "
                            prepend-icon="mdi-paperclip"
                            :label="infoFields.material"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-tooltip top v-if="userFiles.material">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="handleFileDelete('material')"
                                :disabled="phase != 'confirmed'"
                              >
                                mdi-delete</v-icon
                              >
                            </template>
                            <span>Remove</span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  handleUpload(
                                    'material',
                                    infoFields.material,
                                    fileTypes.doc
                                  )
                                "
                                :disabled="phase != 'confirmed'"
                                >mdi-upload</v-icon
                              >
                            </template>
                            <span>Upload</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card-text>
                      <v-alert dark color="primary">
                        Click on <v-icon color="error"> mdi-email-fast </v-icon>
                        to send email for reference request. It will turn
                        <v-icon color="success"> mdi-email-check </v-icon>
                        if reference letter received.
                      </v-alert>
                      <v-data-table
                        dense
                        :headers="referenceHeaders"
                        :items="references"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense>
                            <v-toolbar-title>References</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog
                              persistent
                              v-model="referenceDialog"
                              max-width="400px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="phase != 'confirmed'"
                                >
                                  Add
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title> Add Reference </v-card-title>

                                <v-card-text>
                                  <v-text-field
                                    v-model="newReference.name"
                                    :rules="rules.required"
                                    label="Name"
                                    :disabled="phase != 'confirmed'"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newReference.institute"
                                    :rules="rules.required"
                                    label="Institute"
                                    :disabled="phase != 'confirmed'"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newReference.email"
                                    :rules="rules.email"
                                    label="Email"
                                    :disabled="phase != 'confirmed'"
                                  ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="referenceClose"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    :disabled="
                                      !newReference.name ||
                                      !newReference.institute ||
                                      !newReference.email ||
                                      phase != 'confirmed'
                                    "
                                    color="primary darken-1"
                                    text
                                    @click="referenceSave"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="referenceDialogDelete"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="headline">
                                  Are you sure you want to delete this
                                  reference?
                                </v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="deleteReferenceClose"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="primary darken-1"
                                    text
                                    @click="deleteReferenceConfirm"
                                    :disabled="phase != 'confirmed'"
                                  >
                                    OK
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.letter`]="{ item }">
                          <template v-if="!item.sent">
                            <v-progress-circular
                              size="16"
                              v-if="waiting"
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  color="error"
                                  @click="sendReferenceMail(item)"
                                >
                                  mdi-email-fast
                                </v-icon>
                              </template>
                              <span>Send Email Request</span>
                            </v-tooltip>
                          </template>
                          <v-tooltip top v-else-if="!item.received">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="warning"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-email-sync
                              </v-icon>
                            </template>
                            <span>Awating Letter</span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="success"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-email-check
                              </v-icon>
                            </template>
                            <span>Letter Received</span>
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            @click="deleteReferenceItem(item)"
                            v-if="!item.sent && !waiting"
                            :disabled="phase != 'confirmed'"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-tab-item>
                </template>
              </v-tabs>
            </v-form>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import consts from "@/consts";
import utils from "@/utils";

export default {
  data: () => ({
    rules: consts.INPUT_RULES,
    infoFields: consts.APPLICATION_FIELDS,
    basicFields: consts.BASIC_FIELDS,
    researchFields: consts.RESEARCH_FIELDS,
    positionTypes: consts.POSITION_TYPES,
    fileTypes: consts.FILE_TYPES,
    confirmAlert:
      "You would not be able to change basic information once confirmed.",
    alerts: null,
    messages: null,
    waiting: false,
    phase: "",
    formValid: false,
    infoStatus: "",
    confirmPrecheck: "",
    submitPrecheck: "",
    applicationType: "",
    deadline: "",
    userApplicationInfo: {
      full_name: "",
      name_cn: "",
      native_name: "",
      position_type: "",
      nationality: "",
      birthday: null,

      timezone: "Asia/Shanghai",
      current_institute: "",
      current_position: "",
      current_address: "",

      phd_institute: "",
      phd_date: "",
      ads_link: "",

      research: "[]",

      material: 0,

      photo: 0,
      photo_thumbnail: "",

      confirm_precheck: "",
      submit_precheck: "",
    },
    userFiles: {
      photo: null,
      material: null,
    },
    timezones: ["Asia/Shanghai"],

    deleteFileDialog: false,
    deleteFileField: "",
    uploadDialog: false,
    uploadField: "",
    uploadDesc: "",
    uploadTypes: "",
    uploadFile: null,
    isUploading: false,

    researchHeaders: [
      {
        text: "Research Area",
        align: "start",
        sortable: false,
        value: "area",
      },
      {
        text: "Research Interest",
        sortable: false,
        value: "interest",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    researchAreaOptions: [
      "Galaxy & Cosmology",
      "High-energy Astrophysics",
      "Exoplanet",
      "Others",
    ],
    researchAreaSelect: null,
    researchInfo: [],
    researchDialog: false,
    newResearch: {
      area: "",
      interest: "",
    },
    defaultResearch: {
      area: "",
      interest: "",
    },
    researchDeleteIndex: -1,
    researchDialogDelete: false,
    isOtherResearchArea: false,

    referenceHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Institute",
        sortable: false,
        value: "institute",
      },
      {
        text: "Email",
        sortable: false,
        value: "email",
      },
      {
        text: "Letter",
        sortable: false,
        value: "letter",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    references: [],
    referenceDialog: false,
    newReference: {
      id: 0,
      name: "",
      insitute: "",
      email: "",
      letter: null,
    },
    defaultReference: {
      id: 0,
      name: "",
      insitute: "",
      email: "",
      letter: null,
    },
    deleteReference: {
      id: 0,
      name: "",
      insitute: "",
      email: "",
      letter: null,
    },
    referenceDialogDelete: false,

    confirmDialog: false,
    submitDialog: false,
  }),
  created() {
    this.getApplicationInfo();
    this.timezones = Intl.supportedValuesOf("timeZone");
  },
  watch: {
    researchAreaSelect: function (val) {
      if (val == "Others") {
        this.isOtherResearchArea = true;
        this.newResearch.area = "";
      } else {
        this.isOtherResearchArea = false;
        this.newResearch.area = this.researchAreaSelect;
      }
    },
  },
  computed: {},
  methods: {
    getApplicationInfo() {
      requests.get(`/api/application/info`).then((res) => {
        if (!res.data.status) {
          this.alerts = "Application type not selected!";
          return;
        }
        this.applicationType = res.data.type;
        this.deadline = res.data.deadline;

        this.infoStatus = res.data.status;
        if (this.infoStatus == "empty") {
          this.phase = "basic";
          return;
        } else if (this.infoStatus == "saved") {
          this.phase = "basic";
        } else if (this.infoStatus == "confirmed") {
          this.phase = "confirmed";
        } else {
          this.phase = "done";
        }
        const info = res.data.info;
        this.submitPrecheck = info.submit_precheck;
        this.confirmPrecheck = info.confirm_precheck;
        for (const field in this.userApplicationInfo) {
          if (res.data.info[field]) {
            this.userApplicationInfo[field] = res.data.info[field];
          }
        }
        this.researchInfo = JSON.parse(info.research);
        this.getUserFiles();
        this.getReferences();
      });
    },
    getUserFiles() {
      requests
        .get(`/api/users/files`)
        .then((res) => {
          const files = res.data.files;
          if (Object.keys(files).length === 0) {
            return;
          }
          Object.keys(this.userFiles).forEach((field) => {
            const id = this.userApplicationInfo[field];
            if (id) {
              this.userFiles[field] = files[id];
            }
          });
        })
        .catch(() => {
          console.log("get user files error:");
        });
    },
    getReferences() {
      requests
        .get(`/api/application/reference`)
        .then((res) => {
          this.references = res.data.references;
        })
        .catch(() => {
          console.log("get references error:");
        });
    },

    handlePreview() {
      this.waiting = true;
      requests
        .get(`/api/application/preview`, {
          responseType: "blob",
        })
        .then((res) => {
          this.waiting = false;
          const title = `${this.applicationType}-application-${this.userApplicationInfo.full_name}.pdf`;
          utils.forceFileDownload(res, title);
        })
        .catch(() => {
          this.waiting = false;
          alert("preview failed, pls retry");
        });
    },

    handleSaveBasic() {
      const info = this.basicFields.reduce((result, key) => {
        result[key] = this.userApplicationInfo[key];
        return result;
      }, {});
      requests
        .post(`/api/application/info/basic`, info)
        .then(() => {
          this.$router.go();
        })
        .catch((err) => {
          if (err.response.status == 422) {
            alert(
              "you must fill all mandatory textboxes before save or upload. invalid submit form:" +
                JSON.stringify(err.response.data.detail)
            );
          }
        });
    },
    handleSaveResearch() {
      const info = this.researchFields.reduce((result, key) => {
        result[key] = this.userApplicationInfo[key];
        return result;
      }, {});

      requests
        .post(`/api/application/info/research`, info)
        .then(() => {
          this.$router.go();
        })
        .catch((err) => {
          if (err.response.status == 422) {
            alert(
              "you must fill all mandatory textboxes before save or upload. invalid submit form:" +
                JSON.stringify(err.response.data.detail)
            );
          }
        });
    },

    handleRefresh() {
      this.getApplicationInfo();
    },

    handleConfirm() {
      this.waiting = true;
      requests
        .post(`/api/application/confirm`)
        .then(() => {
          this.waiting = false;
          this.confirmDialog = false;
          this.getApplicationInfo();
        })
        .catch((err) => {
          this.waiting = false;
          alert(err.response.data.detail);
        });
    },

    handleSubmit() {
      this.waiting = true;
      requests
        .post(`/api/application/submit`)
        .then(() => {
          this.waiting = false;
          this.submitDialog = false;
          this.getApplicationInfo();
        })
        .catch((err) => {
          this.waiting = false;
          alert(err.response.data.detail);
        });
    },

    selectFile(file) {
      this.uploadFile = file;
    },

    handleUpload(field, desc, types) {
      this.uploadField = field;
      this.uploadDesc = desc;
      this.uploadTypes = types;
      this.uploadDialog = true;
    },
    uploadClose() {
      this.uploadDialog = false;
      this.$nextTick(() => {
        this.uploadField = "";
        this.uploadDesc = "";
        this.uploadFile = null;
        this.$refs.fileupload.reset();
      });
    },
    uploadSubmit() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      requests
        .post(`/api/application/files/upload/${this.uploadField}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.isUploading = false;
          this.getApplicationInfo();
          this.uploadClose();
        })
        .catch((err) => {
          this.isUploading = false;
          alert(err.response.data.detail);
        });
    },

    handleFileDelete(field) {
      this.deleteFileField = field;
      this.deleteFileDialog = true;
    },
    deleteFileClose() {
      this.deleteFileDialog = false;
      this.$nextTick(() => {
        this.deleteFileField = "";
      });
    },
    deleteFileConfirm() {
      requests
        .post(`/api/application/files/delete/${this.deleteFileField}`)
        .then(() => {
          this.userApplicationInfo[this.deleteFileField] = null;
          this.userFiles[this.deleteFileField] = null;
          this.getApplicationInfo();
          this.deleteFileClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    deleteResearchItem(item) {
      this.researchDeleteIndex = this.researchInfo.indexOf(item);
      this.newResearch = Object.assign({}, item);
      this.researchDialogDelete = true;
    },
    deleteResearchConfirm() {
      this.researchInfo.splice(this.researchDeleteIndex, 1);
      this.deleteResearchClose();
    },
    deleteResearchClose() {
      this.researchDialogDelete = false;
      this.$nextTick(() => {
        this.newResearch = Object.assign({}, this.defaultResearch);
        this.$refs.form.resetValidation();
        this.researchDeleteIndex = -1;
        this.researchInfo.research = JSON.stringify(this.researchInfo);
      });
    },
    researchSave() {
      this.researchInfo.push(this.newResearch);
      this.researchClose();
    },
    researchClose() {
      this.researchDialog = false;
      this.$nextTick(() => {
        this.newResearch = Object.assign({}, this.defaultResearch);
        this.isOtherResearchArea = false;
        this.researchAreaSelect = null;
        this.$refs.form.resetValidation();
        this.userApplicationInfo.research = JSON.stringify(this.researchInfo);
      });
    },

    deleteReferenceItem(item) {
      this.deleteReference = item;
      this.referenceDialogDelete = true;
    },
    deleteReferenceConfirm() {
      requests
        .post(`/api/application/reference/delete/${this.deleteReference.id}`)
        .then(() => {
          const idx = this.references.indexOf(this.deleteReference);
          this.references.splice(idx, 1);
          this.deleteFileClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.deleteReferenceClose();
    },
    deleteReferenceClose() {
      this.referenceDialogDelete = false;
      this.$nextTick(() => {
        this.deleteReference = Object.assign({}, this.defaultReference);
      });
    },

    referenceClose() {
      this.referenceDialog = false;
      this.$nextTick(() => {
        this.newReference = Object.assign({}, this.defaultReference);
        this.$refs.form.resetValidation();
      });
    },
    referenceSave() {
      requests
        .post(`/api/application/reference/add`, this.newReference)
        .then(() => {
          this.getReferences();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
      this.referenceClose();
    },

    sendReferenceMail(item) {
      this.waiting = true;
      requests
        .post(`/api/application/reference/${item.id}/mail`)
        .then(() => {
          alert(
            "Email request sent, please wait for reference letter received!"
          );
          this.getReferences();
          this.waiting = false;
        })
        .catch((err) => {
          alert(err.response.data.detail);
          this.waiting = false;
        });
    },
  },
};
</script>
